<template>
  <el-main>
    <div class="searchdiv">
      <div class="searchform">
        <el-select size="mini" v-model="searchinfo.status" placeholder="处理状态" clearable>
          <el-option value="0" label="待处理"></el-option>
          <el-option value="1" label="已处理"></el-option>
        </el-select>
        <el-button type="primary" @click="dosearch()" size="mini" icon="el-icon-search" :disabled="isbusy" :loading="isbusy">
          {{ $t('global.search') }}
        </el-button>
      </div>
    </div>
    <div class="pagecontent">
      <el-table ref="multipleTable"
                border
                size="mini"
                :data="list"
                tooltip-effect="dark"
                style="width: 100%">
        <el-table-column
            :label="$t('global.action')"
            width="135"
            fixed="left">
          <template slot-scope="scope">
            <el-dropdown size="mini" split-button type="primary" @command="handleCommand">
              更多菜单
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item icon="el-icon-reading" :command="composeValue(scope.row,'edit')">查看</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
        <el-table-column
                label="留言内容"
                prop="title"
                min-width="300">
        </el-table-column>
        <el-table-column
            label="回复内容"
            min-width="300">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 1 && scope.row.reply">{{scope.row.reply}}</span>
            <span v-else>---</span>
          </template>
        </el-table-column>
        <el-table-column
                label="回复时间"
                width="140">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 1">{{scope.row.reply_time}}</span>
            <span v-else>---</span>
          </template>
        </el-table-column>
        <el-table-column
            label="处理状态"
            width="100">
          <template slot-scope="scope">
            <el-tag type="info" size="small" v-if="scope.row.status == 0">待处理</el-tag>
            <el-tag type="success" size="small" v-else>已处理</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            :label="$t('grade.created_at')"
            width="180" prop="created_at">
        </el-table-column>
      </el-table>
      <el-pagination
          background
          @size-change="SizeChange"
          @current-change="getlist"
          :current-page="searchinfo.page"
          :page-sizes="PAGE.pageSizes"
          :page-size="searchinfo.pagesize"
          :layout="PAGE.layout"
          :total="totalRows">
      </el-pagination>
    </div>
    <el-dialog title="查看详情"
               size="mini"
               :visible.sync="isshowdialog"
               :close-on-click-modal="false" custom-class="w70">
      <el-form :model="gradeformdata" :rules="rules" ref="orderform" :show-message="false">
        <el-form-item label="留言内容" label-width="100px">
          <div>{{ gradeformdata.title }}</div>
        </el-form-item>
        <el-form-item label="开票状态" label-width="100px">
          <div v-if="gradeformdata.status == 0"><el-tag type="info">待处理</el-tag></div>
          <div v-else><el-tag type="success">已开票</el-tag></div>
        </el-form-item>
        <el-form-item label="留言附图" label-width="100px">
          <div>
            <div style="display:inline-block; margin-right:10px;" v-for="(pic,index) in gradeformdata.pics" :key="index">
              <a :href="pic" target="_blank"> <img v-if="pic" :src="pic" class="avatar"></a>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="留言回复" prop="reply" label-width="100px">
          <el-input type="text" v-model="gradeformdata.reply" autocomplete="off" v-if="gradeformdata.status == 0"></el-input>
          <div v-else>{{ gradeformdata.reply }}</div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="isshowdialog = false" :disabled="isbusy" :loading="isbusy">关闭</el-button>
        <el-button type="primary" @click="dosubmit('orderform')" :disabled="isbusy" :loading="isbusy" v-if="gradeformdata.status==0">
          {{ $t('global.submit') }}
        </el-button>
      </div>
    </el-dialog>
  </el-main>
</template>

<script>
export default {
  name: 'suggest',
  data() {
    return {
      searchinfo: {
        page: 1,
        pagesize: this.PAGE.pageSize,
        status: ''
      },
      list: [],
      region:[],
      totalRows: 0,
      gradeformdata: {},
      rules: {},
      modifyform:{
        id:0,
        reply:'',
      },
      dothing:'',
      isshowdialog: false,
      isshowmodifydialog:false,
      isbusy: false,
    }
  },
  methods: {
    handleCommand(command){
      if(command.command == 'edit'){
        this.isshowdialog = true;
        this.gradeformdata = null;
        this.gradeformdata = this.cloneData(command.items);
      }
    },
    composeValue(items,command){
      return {
        'items':items,
        'command':command
      };
    },
    SizeChange(val) {
      this.searchinfo.pagesize = val;
      this.getlist(1);
    },
    editgrade(item) {
      this.gradeformdata = null;
      this.gradeformdata = this.cloneData(item);
      this.isshowdialog = true;
    },
    async dosearch() {
      this.getlist(1);
    },
    async getlist(page) {
      this.searchinfo.page = page || this.searchinfo.page;
      this.isbusy = true;
      let res = await this.httpget('/system/suggest/index', this.searchinfo);
      if (res.code === 0) {
        this.list = [];
        res.data.data.forEach((data) => {
          this.list.push(data);
        })
        this.totalRows = res.data.total;
        this.isbusy = false;
      } else {
        this.isbusy = false;
      }

    },
    dosubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.suresubmit();
        } else {
          return false;
        }
      });
    },
    async suresubmit() {
      this.isbusy = true;
      let res = await this.httppost('/system/suggest/save', this.gradeformdata);
      if (res.code === 0) {
        this.$message({type: 'success', message: res.msg});
        this.getlist(1);
        this.gradeformdata = {};
        this.isshowdialog = false;
        this.isbusy = false;
      } else {
        this.isbusy = false;
        this.$message({type: 'error', message: res.msg});
      }
    },
    async deluser(item) {
      this.$confirm(this.$t('global.areyousure')).then(async () => {
        this.isbusy = true;
        let res = await this.httpput('/system/suggest/delete', {id: item.id});
        if (res.code === 0) {
          this.$message({type: 'success', message: res.msg});
          this.getlist();
        } else {
          this.$message({type: 'error', message: res.msg});
        }
        this.isbusy = false;
      }).catch(() => {
        this.isbusy = false;
        this.$message({type: 'info', message: 'Canceled'});
      });
    },
  },
  mounted() {
    this.getlist(1);
  },
  computed: {
  },
}
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}

.avatar {
  height: 100px;
  display: block;
}

.edui-popup-content.edui-default{ height: auto !important; }
.edui-combox-body.edui-default{
  height:25px;

}
.edui-default .edui-toolbar .edui-combox-body .edui-button-body{
  line-height: 25px;
}
.edui-default .edui-toolbar .edui-combox-body .edui-button-body{
  line-height: 25px !important;
}
.edui-default .edui-toolbar .edui-splitborder{
  height:25px !important;
}
.edui-default .edui-toolbar .edui-combox-body .edui-arrow{
  height:25px !important;
  width:13px !important;
}
#edui_fixedlayer{
  z-index:100000 !important;
}
</style>
