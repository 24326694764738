<template>
  <el-main>
    <div class="searchdiv">
      <div class="searchform">
        <el-select size="mini" v-model="searchinfo.region" placeholder="选择区域">
          <template v-for="item in region">
            <el-option :value="item.id" :label="item.region"></el-option>
          </template>
        </el-select>
        <el-input v-model="searchinfo.orderno"
                  size="mini"
                  placeholder="订单编号"
                  label="订单编号"
                  clearable class="iw-lg"></el-input>
        <el-select size="mini" v-model="searchinfo.status" placeholder="开票状态" clearable>
          <el-option value="3" label="已取消"></el-option>
          <el-option value="0" label="待支付"></el-option>
          <el-option value="1" label="待开票"></el-option>
          <el-option value="2" label="已开票"></el-option>
        </el-select>
        <el-button type="primary" @click="dosearch()" size="mini" icon="el-icon-search" :disabled="isbusy" :loading="isbusy">
          {{ $t('global.search') }}
        </el-button>
        <a class="el-button--primary" :href="'http://www.kpiao.com/system/upload/download?orderno='+searchinfo.orderno+'&status='+searchinfo.status+'&region='+searchinfo.region" style="width:90px; line-height: 28px; font-size:12px; margin-left:10px; text-align: center;">导出数据</a>
      </div>
    </div>
    <div class="pagecontent">
      <el-table ref="multipleTable"
                border
                size="mini"
                :data="list"
                tooltip-effect="dark"
                style="width: 100%">
        <el-table-column
            :label="$t('global.action')"
            width="135"
            fixed="left">
          <template slot-scope="scope">
            <el-dropdown size="mini" split-button type="primary" @command="handleCommand">
              更多菜单
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item icon="el-icon-reading" :command="composeValue(scope.row,'edit')">查看详情</el-dropdown-item>
                <el-dropdown-item icon="el-icon-edit" :command="composeValue(scope.row,'modify')" v-if="scope.row.delete_time == 0">开票编辑</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
        <el-table-column
                label="区域名称"
                prop="region"
                width="100">
        </el-table-column>
        <el-table-column
            label="订单编号"
            prop="orderno"
            width="130">
        </el-table-column>
        <el-table-column
            label="会员昵称"
            prop="nickname"
            width="100">
        </el-table-column>
        <el-table-column
            label="园区名称"
            prop="cname"
            width="120">
        </el-table-column>
        <el-table-column
            label="开票金额"
            prop="totalprice"
            width="100">
        </el-table-column>
        <el-table-column
            label="开票类型"
            prop="dname"
            width="120">
        </el-table-column>
        <el-table-column
            label="抬头公司"
            prop="company"
            width="130">
        </el-table-column>
        <el-table-column
            label="公司税号"
            prop="tax"
            width="200">
        </el-table-column>
        <el-table-column
            label="收票地址"
            width="300">
          <template slot-scope="scope">
            {{ scope.row.province }} {{ scope.row.citys }} {{ scope.row.area }} {{ scope.row.address }} {{ scope.row.mobile }}
          </template>
        </el-table-column>
        <el-table-column
            label="开票人"
            prop="realname"
            width="100">
        </el-table-column>
        <el-table-column
            label="开票身份号"
            prop="idcard"
            width="180">
        </el-table-column>
        <el-table-column
            label="订单状态"
            width="100">
          <template slot-scope="scope">
            <el-tag type="info" size="small" v-if="scope.row.delete_time > 0">已取消</el-tag>
            <el-tag type="info" size="small" v-else-if="scope.row.delete_time == 0 && scope.row.status == 0">待支付</el-tag>
            <el-tag type="success" size="small" v-else-if="scope.row.delete_time == 0 && scope.row.status == 1">待开票</el-tag>
            <el-tag type="success" size="small" v-else-if="scope.row.delete_time == 0 &&  scope.row.status == 2">已开票</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            :label="$t('grade.created_at')"
            width="180" prop="created_at">
        </el-table-column>
      </el-table>
      <el-pagination
          background
          @size-change="SizeChange"
          @current-change="getlist"
          :current-page="searchinfo.page"
          :page-sizes="PAGE.pageSizes"
          :page-size="searchinfo.pagesize"
          :layout="PAGE.layout"
          :total="totalRows">
      </el-pagination>
    </div>
    <el-dialog title="查看详情"
               size="mini"
               :visible.sync="isshowdialog"
               :close-on-click-modal="false" custom-class="w70">
      <el-form :model="gradeformdata" :rules="rules" ref="orderform" :show-message="false">
        <el-form-item label="园区信息" label-width="100px">
          <div>园区名称：{{ gradeformdata.pname }}</div>
          <div>园区说明：{{ gradeformdata.explain }}</div>
        </el-form-item>
        <el-form-item label="所属类目" label-width="100px">
          {{ gradeformdata.cname }}
        </el-form-item>
        <el-form-item label="开票明细" label-width="100px">
          <div v-for="(item,index) in gradeformdata.kpxx" :key="index" style="display:inline-block; width:200px;">
            <div><strong>开票明细{{index+1}}</strong></div>
            <div>开票内容：{{item.kpnr}}</div>
            <div>规格型号：{{item.ggxh}}</div>
            <div>计量单位：{{item.jldw}}</div>
            <div>数量：{{item.sl}}</div>
            <div>单价（元）：{{item.dj}}</div>
            <div>开票金额（元）：{{item.kpje}}</div>
          </div>
        </el-form-item>
        <el-form-item label="开票金额" label-width="100px">
          {{gradeformdata.totalprice}}
        </el-form-item>
        <el-form-item label="开票人" label-width="100px">
          <div>
            <div style="display:inline-block; margin-right:10px;">
              <a :href="gradeformdata.front_idcard" target="_blank"> <img v-if="gradeformdata.front_idcard" :src="gradeformdata.front_idcard" class="avatar"></a>
            </div>
            <div style="display:inline-block; margin-right:10px;">
              <a :href="gradeformdata.front_idcard" target="_blank"> <img v-if="gradeformdata.front_idcard" :src="gradeformdata.front_idcard" class="avatar"></a>
            </div>
          </div>
          <div>真实姓名：{{ gradeformdata.realname }}</div>
          <div>身份证号：{{ gradeformdata.idcard }}</div>
          <div>身份证地址：{{ gradeformdata.idcard_addr }}</div>
          <div>联系电话：{{ gradeformdata.mobile }}</div>
        </el-form-item>
        <el-form-item label="发票抬头" label-width="100px">
          <div>公司名称：{{ gradeformdata.company }}</div>
          <div>公司税号：{{ gradeformdata.tax }}</div>
          <div>公司地址：{{ gradeformdata.caddress }}</div>
          <div>开户行：{{ gradeformdata.kh_bank }}</div>
          <div>银行卡号：{{ gradeformdata.bank_card }}</div>
          <div>联系电话：{{ gradeformdata.tmobile }}</div>
        </el-form-item>
        <el-form-item label="收票地址" label-width="100px">
          <div>省市区：{{ gradeformdata.province }} {{ gradeformdata.citys }} {{ gradeformdata.area }}</div>
          <div>详细地址：{{ gradeformdata.address }}</div>
          <div>收件人：{{ gradeformdata.receiver }} {{ gradeformdata.rmobile }}</div>
        </el-form-item>
        <el-form-item label="开票状态" label-width="100px">
          <div v-if="gradeformdata.delete_time > 0"><el-tag type="info">已取消</el-tag></div>
          <div v-else-if="gradeformdata.delete_time == 0 && gradeformdata.status == 0"><el-tag type="info">待支付</el-tag></div>
          <div v-else-if="gradeformdata.delete_time == 0 && gradeformdata.status == 1"><el-tag type="success">待开票</el-tag></div>
          <div v-else-if="gradeformdata.delete_time == 0 && gradeformdata.status == 2"><el-tag type="success">已开票</el-tag></div>
        </el-form-item>
        <el-form-item label="合同附件" label-width="100px">
          <div>
            <div style="display:inline-block; margin-right:10px;" v-for="(pic,index) in gradeformdata.kpath" :key="index">
              <a :href="pic" target="_blank"> <img v-if="pic" :src="pic" class="avatar"></a>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="isshowdialog = false" :disabled="isbusy" :loading="isbusy">关闭</el-button>
      </div>
    </el-dialog>
    <el-dialog title="修改操作"
               size="mini"
               :visible.sync="isshowmodifydialog"
               :close-on-click-modal="false">
      <el-form size="mini" :model="modifyform" :rules="rules" ref="modifyform" :show-message="false">
        <el-form-item label="开票状态" label-width="100px">
          <el-select v-model="modifyform.status" placeholder="修改开票状态">
            <el-option label="待支付" :value="0">待支付</el-option>
            <el-option label="待开票" :value="1">待开票</el-option>
            <el-option label="已开票" :value="2">已开票</el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上传发票" prop="imgurl" label-width="100px">
          <el-upload
              class="avatar-uploader"
              :action="uploadurl"
              :headers="uploadheader"
              :show-file-list="false"
              :on-success="uploadsuccess"
              :before-upload="beforeupload">
            <img v-if="modifyform.imgurl" :src="modifyform.imgurl" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="完税证明" prop="explains" label-width="100px">
          <el-upload
                  class="avatar-uploader"
                  :action="uploadurl"
                  :headers="uploadheader"
                  :show-file-list="false"
                  :on-success="uploadsuccessed"
                  :before-upload="beforeupload">
            <img v-if="modifyform.explains" :src="modifyform.explains" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="物流公司" prop="wuliu" label-width="100px">
          <el-input type="text" v-model="modifyform.wuliu" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="物流单号" prop="wuliu_no" label-width="100px">
          <el-input type="text" v-model="modifyform.wuliu_no" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="isshowmodifydialog = false" :disabled="isbusy" :loading="isbusy">{{ $t('global.cancel') }}
        </el-button>
        <el-button type="primary" @click="domodifysubmit('modifyform')" :disabled="isbusy" :loading="isbusy">
          {{ $t('global.submit') }}
        </el-button>
      </div>
    </el-dialog>
  </el-main>
</template>

<script>
export default {
  name: 'order',
  data() {
    return {
      searchinfo: {
        page: 1,
        pagesize: this.PAGE.pageSize,
        orderno: '',
        region: '',
        status: ''
      },
      list: [],
      region:[],
      totalRows: 0,
      defaultuserinfo: {
        id: 0,
        name: '',
        picture: '',
        kpath:[],
        remarks:'',
        ordid:0,
        status: 1,
      },
      modifyform:{
        id:0,
        status:0,
        imgurl:'',
        explains:'',
        wuliu:'',
        wuliu_no:''
      },
      gradeformdata: {},
      rules: {},
      dothing:'',
      isshowdialog: false,
      isshowmodifydialog:false,
      isbusy: false,
      dmainUrl: process.env.VUE_APP_API_ROOT,
      uploadurl: process.env.VUE_APP_API_ROOT+'/system/upload/images?storage=invoice',
      uploadheader: {Authorization: this.$store.getters.getToken},
    }
  },
  methods: {
    domodifysubmit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.isbusy = true;
          this.modifyform.dothing = this.dothing;
          let res = await this.httppost('/system/order/save', this.modifyform);
          if (res.code === 0) {
            this.$message({type: 'success', message: res.msg});
            this.modifyform = {
              id:0,
              status:0,
              imgurl:'',
              wuliu:'',
              wuliu_no:''
            };
            this.getlist(1);
            this.isshowmodifydialog = false;
            this.isbusy = false;
          } else {
            this.isbusy = false;
            this.$message({type: 'error', message: res.msg});
          }
        } else {
          return false;
        }
      });
    },
    async getregion(){
      let res = await this.httpget('/system/park/getregion');
      if( res.code == 0 ){
        this.region = res.data;
        return true;
      } else {
        console.log('failed');
      }
    },
    handleCommand(command){
      if(command.command == 'edit'){
        this.isshowdialog = true;
        this.gradeformdata = null;
        this.gradeformdata = this.cloneData(command.items);
      } else {
        this.dothing = command.command;
        let uInfo = command.items;
        let newform = {};
        newform = {
          id:uInfo.id,
          status:uInfo.status,
          imgurl:uInfo.imgurl,
          explains:uInfo.explains,
          wuliu:uInfo.wuliu,
          wuliu_no:uInfo.wuliu_no
        };
        this.modifyform = newform;
        this.isshowmodifydialog = true;
      }

    },
    composeValue(items,command){
      return {
        'items':items,
        'command':command
      };
    },
    SizeChange(val) {
      this.searchinfo.pagesize = val;
      this.getlist(1);
    },
    editgrade(item) {
      this.gradeformdata = null;
      this.gradeformdata = this.cloneData(item);
      this.isshowdialog = true;
    },
    addgrade(formName) {
      this.isshowdialog = true;
      this.$nextTick(() => {
        this.gradeformdata = this.cloneData(this.defaultuserinfo);
        this.$refs[formName].resetFields();
      });
    },
    async dosearch() {
      this.getlist(1);
    },
    async getlist(page) {
      this.searchinfo.page = page || this.searchinfo.page;
      this.isbusy = true;
      let res = await this.httpget('/system/order/index', this.searchinfo);
      if (res.code === 0) {
        this.list = [];
        res.data.data.forEach((data) => {
          this.list.push(data);
        })
        this.totalRows = res.data.total;
        this.isbusy = false;
      } else {
        this.isbusy = false;
      }

    },
    dosubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.suresubmit();
        } else {
          return false;
        }
      });
    },
    async suresubmit() {
      this.isbusy = true;
      let res = await this.httppost('/system/order/save', this.gradeformdata);
      if (res.code === 0) {
        this.$message({type: 'success', message: res.msg});
        this.getlist(1);
        this.gradeformdata = {};
        this.isshowdialog = false;
        this.isbusy = false;
      } else {
        this.isbusy = false;
        this.$message({type: 'error', message: res.msg});
      }
    },
    async setenable(item) {
      this.$confirm(this.$t('global.areyousure')).then(async () => {
        this.isbusy = true;
        let res = await this.httpput('/system/order/setenable', {id: item.id});
        if (res.code === 0) {
          this.$message({type: 'success', message: res.msg});
          let idx = this.list.findIndex((value) => {
            return value.id === item.id
          })
          if (idx !== -1) {
            this.list[idx].status = Math.abs(this.list[idx].status - 1);
          }
          this.isbusy = false;
        } else {
          this.isbusy = false;
          this.$message({type: 'error', message: res.msg});
        }
      }).catch(() => {
        this.isbusy = false;
        this.$message({type: 'info', message: 'Canceled'});
      });
    },
    async deluser(item) {
      this.$confirm(this.$t('global.areyousure')).then(async () => {
        this.isbusy = true;
        let res = await this.httpput('/system/order/delete', {id: item.id});
        if (res.code === 0) {
          this.$message({type: 'success', message: res.msg});
          this.getlist();
        } else {
          this.$message({type: 'error', message: res.msg});
        }
        this.isbusy = false;
      }).catch(() => {
        this.isbusy = false;
        this.$message({type: 'info', message: 'Canceled'});
      });
    },
    uploadsuccess(res){
      if(res.code===0){
        if(res.data[0]
            && res.data[0].indexOf(process.env.VUE_APP_IMG_DOMAIN)===-1
            && res.data[0].indexOf('http://')===-1){
          res.data[0] = process.env.VUE_APP_IMG_DOMAIN + '/storage/' + res.data[0];
        }
        this.modifyform.imgurl = res.data[0];
      }else{
        this.$message({type: 'error', message: res.msg});
      }
    },
    uploadsuccessed(res){
      if(res.code===0){
        if(res.data[0]
                && res.data[0].indexOf(process.env.VUE_APP_IMG_DOMAIN)===-1
                && res.data[0].indexOf('http://')===-1){
          res.data[0] = process.env.VUE_APP_IMG_DOMAIN + '/storage/' + res.data[0];
        }
        this.modifyform.explains = res.data[0];
      }else{
        this.$message({type: 'error', message: res.msg});
      }
    },
    beforeupload(file) {
      const isJPG = (file.type === 'image/jpeg'||file.type === 'image/png');
      const isLt2M = file.size / 1024 / 1024 < 8;
      if (!isJPG) {
        this.$message.error('Upload avatar pictures can only be in JPG or PNG format!');
      }
      if (!isLt2M) {
        this.$message.error('Upload avatar image size can\'t exceed 2MB!');
      }
      return isJPG && isLt2M;
    },
  },
  mounted() {
    this.getlist(1);
    this.getregion();
  },
  computed: {
  },
}
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}

.avatar {
  height: 100px;
  display: block;
}

.edui-popup-content.edui-default{ height: auto !important; }
.edui-combox-body.edui-default{
  height:25px;

}
.edui-default .edui-toolbar .edui-combox-body .edui-button-body{
  line-height: 25px;
}
.edui-default .edui-toolbar .edui-combox-body .edui-button-body{
  line-height: 25px !important;
}
.edui-default .edui-toolbar .edui-splitborder{
  height:25px !important;
}
.edui-default .edui-toolbar .edui-combox-body .edui-arrow{
  height:25px !important;
  width:13px !important;
}
#edui_fixedlayer{
  z-index:100000 !important;
}
</style>
